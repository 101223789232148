import { render, staticRenderFns } from "./LayoutOrganizerDashboard.vue?vue&type=template&id=08a22cf1"
import script from "./LayoutOrganizerDashboard.vue?vue&type=script&lang=js"
export * from "./LayoutOrganizerDashboard.vue?vue&type=script&lang=js"
import style0 from "./LayoutOrganizerDashboard.vue?vue&type=style&index=0&id=08a22cf1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports