<template>
  <div
    class="horizontal-layout h-100 horizontal-menu footer-static w-100"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="navbar header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed navbar-light navbar-expand floating-nav"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >

        <div class="navbar-container d-flex content align-items-center">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <b-link
                class="navbar-brand d-flex align-items-center ml-1"
                :to="{name:'dashboard', params: {id: $route.params.id}}"
              >
                <h2 class="brand-text mb-0">
                  {{ appName }} - Organizer
                </h2>
              </b-link>
            </li>
          </ul>

          <div
            class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block"
          >

            <horizontal-nav-menu-items
              :items="dashboardMenu"
            />
          </div>

          <!-- Left Col -->
          <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

          <!-- Right Col -->
          <b-navbar-nav class="nav align-items-center ml-auto">
            <user-dropdown />
          </b-navbar-nav>
        </div>
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BBreadcrumb, BBreadcrumbItem, BCol,
  BImg, BLink, BNavbar, BNavbarNav, BRow, BSpinner,
} from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault
from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached
from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'

// Vertical Menu
/* eslint-disable import/order */
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from '@core/layouts/layout-horizontal/mixinLayoutHorizontal'
import HorizontalNavMenu from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import useLayoutHorizontal from '@core/layouts/layout-horizontal/useLayoutHorizontal'

import HorizontalNavMenuItems
from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BBreadcrumbItem,
    BSpinner,
    BCol,
    BRow,
    BBreadcrumb,
    BLink,
    BImg,
    UserDropdown,
    BNavbarNav,
    HorizontalNavMenuItems,
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  mixins: [mixinLayoutHorizontal],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('organizer/fetchDashboardOrganizer', this.$route.params.id)
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    const dashboardMenu = [
      {
        title: 'Home',
        icon: 'HomeIcon',
        route: 'dashboard',

      },
      {
        title: 'My Tournaments',
        icon: 'GridIcon',
        route: 'dashboard-tournaments',
      },
      {
        title: 'Faq & Support',
        icon: 'InfoIcon',
        href: 'https://legit.si',
      },
      {
        title: 'Return to platform',
        icon: 'GridIcon',
        route: 'home',
      },
    ]

    // Vertical Menu
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
      resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,

      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
      dashboardMenu,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";

@media (min-width: 576px) {
}

.content-wrapper {
  padding: 1rem;
}

.horizontal-menu {
  .header-navbar {
    &.navbar-brand-center {
      .navbar-header {
        left: 0;
      }
    }
    &.navbar-fixed {
      position: fixed;
      width: inherit;
      margin: 0 !important;
      border-radius: 0;
    }
    .navbar-horizontal {
      ul#main-menu-navigation > li {
        padding: 0;
      }
    }
  }
}

.horizontal-menu .header-navbar.navbar-fixed {
  margin: 0 !important;
  border-radius: 0;
  left: 0;
}

.horizontal-menu .navbar.header-navbar .navbar-container {
  padding: 0.5rem !important;
}

.brand-text {
  font-size: 20px;
  color: #7367f0 !important;
}

</style>
