var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "horizontal-layout h-100 horizontal-menu footer-static w-100",
    staticStyle: {
      "height": "inherit"
    },
    attrs: {
      "data-col": _vm.isNavMenuHidden ? '1-column' : null
    }
  }, [_c('b-navbar', {
    staticClass: "navbar header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed navbar-light navbar-expand floating-nav",
    style: {
      backgroundColor: _vm.navbarType === 'static' && _vm.scrolledTo && _vm.skin === 'light' ? 'white' : null,
      boxShadow: _vm.navbarType === 'static' && _vm.scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null
    },
    attrs: {
      "toggleable": false
    }
  }, [_vm._t("navbar", [_c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "navbar-brand d-flex align-items-center ml-1",
    attrs: {
      "to": {
        name: 'dashboard',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_c('h2', {
    staticClass: "brand-text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " - Organizer ")])])], 1)]), _c('div', {
    staticClass: "header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block"
  }, [_c('horizontal-nav-menu-items', {
    attrs: {
      "items": _vm.dashboardMenu
    }
  })], 1), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
  }), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_c('user-dropdown')], 1)], 1)], {
    "toggleVerticalMenuActive": _vm.toggleVerticalMenuActive
  })], 2), _c('transition', {
    attrs: {
      "name": _vm.routerTransition,
      "mode": "out-in"
    }
  }, [_c(_vm.layoutContentRenderer, {
    key: _vm.layoutContentRenderer === 'layout-content-renderer-left' ? _vm.$route.meta.navActiveLink || _vm.$route.name : null,
    tag: "component",
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (index, name) {
      return {
        key: name,
        fn: function fn(data) {
          return [_vm._t(name, null, null, data)];
        }
      };
    })], null, true)
  })], 1), _c('footer', {
    staticClass: "footer footer-light",
    class: [_vm.footerTypeClass]
  }, [_vm._t("footer", [_c('app-footer')])], 2), _vm._t("customizer")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }